import { eventLoader } from "@otto-ec/global-event-loader"
import * as animation from "@otto-ec/global-utils/animation"

export function scrollToElement(currentTarget: HTMLInputElement, duration: number) {
    if (!isInViewport(currentTarget)) {
        animation.scrollTo(currentTarget, duration)
    }
}

export function isInViewport(element: HTMLElement) {
    const win = window,
        docElem = document.documentElement,
        rect = element.getBoundingClientRect()

    return (
        element.style.display !== "none" &&
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom > 0 &&
        rect.right > 0 &&
        rect.bottom <= (win.innerHeight || docElem.clientHeight) &&
        rect.right <= (win.innerWidth || docElem.clientWidth)
    )
}

export function onAllPreloadScriptsLoaded(callback: (() => void) | (() => Promise<void>)) {
    eventLoader().onAllPreloadScriptsLoaded(10, callback)
}
