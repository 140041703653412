import type {AsyncNamespace, OttoNexus} from "@otto-ec/global-resources/nexus";
import type { Sharing } from "./sharing"
import type { Global } from "./global";
import type { PushNotifications } from "./pushNotifications";

declare global {
    interface NexusInterfaces {
        /**
 *
 */
        apps: {
            sharing: AsyncNamespace<Sharing>,
            global: AsyncNamespace<Global>,
            pushNotifications: AsyncNamespace<PushNotifications>
        }
    }
}

export type apps = OttoNexus["apps"]

export const apps: OttoNexus["apps"] = otto.apps