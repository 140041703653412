import { o_global } from "@gr-common/body/namespaces";
import { assignNamespace } from "@gr-common/body/namespace-utils";
import { enrichPageImpression } from "./pageImpression";

import { Hybrid, hybrid } from "./lib/hybrid";
import { Orientation, orientation } from "./lib/orientation";
import { isTouchable } from "./lib/touch";
import { screen } from "./lib/screen";
import { os, OsType } from "./lib/os";
import { Browser, browser } from "./lib/browser";
import { DeviceType, type } from "./lib/type";

export * from "./lib/browser";
export type * from "./lib/events";
export * from "./lib/hybrid";
export * from "./lib/orientation";
export * from "./lib/os";
export * from "./lib/screen";
export * from "./lib/touch";
export * from "./lib/type";
export * from "./lib/preferences";

/**
 *
 */
export type GlobalDevice = {
  /*                                          */
  Hybrid: typeof Hybrid;
  /*                                          */
  Orientation: typeof Orientation;
  isTouchable: boolean;
  os: OsType;
  screen: typeof screen;
  browser: Browser;
  orientation: Orientation;
  hybrid: Hybrid;
  type: DeviceType;
};

const device = assignNamespace(
  o_global,
  "device",
  {
    Hybrid,
    Orientation,
    isTouchable: isTouchable(),
    os: os(),
    screen,
    browser: browser(),
    orientation: orientation(),
    hybrid: undefined as unknown as Hybrid,
    type: type(),
  },
  {
    breakpoint: o_global.breakpoint,
  },
);

/*                                          */
o_global.device.hybrid = hybrid();

/*                    */
if (window.o_util?.toggle?.get("ASSETS_device_preferences", false)) {
  enrichPageImpression();
}

otto.device.assignNamespace({
  getPointerType: () => ({ isTouchable: device.isTouchable, isHybrid: device.hybrid.isHybrid }),
});
