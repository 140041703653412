/*                                     */
import { o_util } from "@gr-common/head/namespaces";
import { type OttoNexus, otto } from "./nexus";

/**
 *
 */
export type ApmRum = OttoNexus["apm"];

/*                     */
export const apm: ApmRum = otto.apm ?? {};
export const agent: ApmRum["agent"] = apm.agent;
export const getCurrentTransaction: ApmRum["getCurrentTransaction"] = apm.getCurrentTransaction;
export const startSpan: ApmRum["startSpan"] = apm.startSpan;
export const captureError: ApmRum["captureError"] = apm.captureError;
export const mark: ApmRum["mark"] = apm.mark;
export const addLabels: ApmRum["addLabels"] = apm.addLabels;
export const sendCustomError: ApmRum["sendCustomError"] = apm.sendCustomError;

/*                   */
export const apmSync = o_util?.apm ?? {};
export const runWithSpan = apmSync.runWithSpan;
export const withSpan = apmSync.withSpan;
