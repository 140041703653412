export const appendSpinnerToFormButtons = (documentObject: Document, cssFormSelector: string) => {
    const form = documentObject.querySelector(cssFormSelector)
    if (form) {
        const buttons = form.querySelectorAll<HTMLOcButtonV1Element>("oc-button-v1[type='submit']")
        buttons.forEach((button) => {
            button.addEventListener("click", () => {
                button.loading = true
                /*                                                                                         */
                setTimeout(() => button.loading = false, 30_000)
            })
        })
    }
}

