<svelte:options
  customElement={{
    tag: "oc-spinner-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      size: { type: "String", reflect: true },
      variant: { type: "String", reflect: true },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./SpinnerV1.types";

  let { size = "100", variant = "inverted" }: Props = $props();

  const sizes = {
    "200": ["M2 24C2 18.1652 4.31785 12.5694 8.44365 8.44365C12.5695 4.31785 18.1652 2 24 2", 2],
    "100": ["M1 12C1 9.08262 2.15893 6.28472 4.22183 4.22183C6.28473 2.15893 9.08262 1 12 1", 1],
    "50": [
      "M0.5 6C0.5 4.54131 1.07946 3.14236 2.11091 2.11091C3.14236 1.07946 4.54131 0.5 6 0.5",
      0.5,
    ],
  } as const;

  const sizeData = $derived(sizes[size] ?? sizes["50"]);
  const viewBoxSize = $derived(24 * sizeData[1]);
  const circleSize = $derived([12 * sizeData[1], 11 * sizeData[1]] as const);
</script>

<!--TODO: load svg from url -->
<svg
  viewBox="0 0 {viewBoxSize} {viewBoxSize}"
  fill="none"
  aria-hidden="true"
  xmlns="http:/*                  */
>
  <circle cx={circleSize[0]} cy={circleSize[0]} r={circleSize[1]} />
  <path d={sizeData[0]} stroke-linecap="round" stroke-linejoin="round" />
</svg>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  /**
 *
 */
  @keyframes spinner-turn {
    to {
      transform: rotate(1turn);
    }
  }

  svg {
    display: block;
    animation: spinner-turn tokens.$oc-component-spinner-animation-duration infinite
      tokens.$oc-component-spinner-animation-easing;
    @media (prefers-reduced-motion) {
      animation: spinner-turn calc(tokens.$oc-component-spinner-animation-duration * 2) infinite
        tokens.$oc-component-spinner-animation-easing;
    }
  }

  :host {
    display: block;
  }

  circle {
    opacity: tokens.$oc-component-spinner-track-opacity;
  }

  :host([variant="default"]) {
    stroke: tokens.$oc-component-spinner-default-fill-color;
  }
  :host([variant="inverted"]) {
    stroke: tokens.$oc-component-spinner-inverted-fill-color;
  }

  :host([size="200"]) {
    width: tokens.$oc-component-spinner-200-size;
    height: tokens.$oc-component-spinner-200-size;
    stroke-width: tokens.$oc-component-spinner-200-stroke-width;
  }
  :host([size="100"]) {
    width: tokens.$oc-component-spinner-100-size;
    height: tokens.$oc-component-spinner-100-size;
    stroke-width: tokens.$oc-component-spinner-100-stroke-width;
  }
  :host([size="50"]) {
    width: tokens.$oc-component-spinner-50-size;
    height: tokens.$oc-component-spinner-50-size;
    stroke-width: tokens.$oc-component-spinner-50-stroke-width;
  }
</style>
