import { ShowConsentBannerUseCase } from './core'
import { VisibilityAdapter } from './adapters'
import { apps } from './singletons'
import { logger } from './logger'

const hideConsentBannerPaths = [
  '/agb',
  '/datenschutz',
  '/impressum',
  '/shoppages/service/ottopayments/datenschutzinformationen_otto_payments',
]

export const showConsentBannerUseCase = new ShowConsentBannerUseCase(
  new VisibilityAdapter(hideConsentBannerPaths, { isApp: apps.isApp() }),
  apps,
  logger
)
