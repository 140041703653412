import { GlobalOnexConfig } from './libs/onex/src'

export const config: GlobalOnexConfig = {
  active: false,
  id: 'E3396',
  smoketestsIncludeOnex: false,
  localE2eTestsIncludeOnex: false,
  activeOnexConfig: {
    StatusQuo: {
      template: 'cookieBanner-onetrust.html',
      styleFile: 'styles-onetrust.scss',
    },
    NewConsB: {
      template: 'cookieBanner-onetrust.html',
      styleFile: 'styles-onetrust--new-cons-b-yellow.scss',
    },
    NewConsC: {
      template: 'cookieBanner-onetrust.html',
      styleFile: 'styles-onetrust--new-cons-c-yellow.scss',
    },
    NewConsD: {
      template: 'cookieBanner-onetrust.html',
      styleFile: 'styles-onetrust--new-cons-d-cozy-pink.scss',
    },
    NewConsE: {
      template: 'cookieBanner-onetrust.html',
      styleFile: 'styles-onetrust--new-cons-e-cozy-pink.scss',
    },
  },
}
