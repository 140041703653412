import { type Span } from "@elastic/apm-rum";
import { startSpan } from "../../exports/apm.js";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                                                          */
export async function runWithSpan<N extends string, T extends string, A extends any[], R>(
  fn: (this: Span | undefined, ...args: A) => R | Promise<R>,
  name: N,
  type?: T | null,
  labels?: Labels | null,
  ...args: A
): Promise<Awaited<R>> {
  const span = await startSpan(name, type);
  if (!span) {
    /*                                       */
    return await fn.call(undefined, ...args);
  }

  if (labels && typeof labels === "object") {
    span.addLabels(labels);
  }

  try {
    /*                                                       */
    return await fn.call(span, ...args);
  } finally {
    span.end();
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                                                          */
export function withSpan<N extends string, T extends string, A extends any[], R>(
  fn: (this: Span | undefined, ...args: A) => R | Promise<R>,
  name: N,
  type?: T | null,
  labels?: Labels | null,
): (...args: A) => Promise<Awaited<R>> {
  return (...args: A) => runWithSpan(fn, name, type, labels, ...args);
}
