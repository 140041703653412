<svelte:options
  customElement={{
    tag: "oc-card-v2",
    shadow: "none",
    /*                       */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      asButton: { type: "Boolean", attribute: "as-button" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import type { Props } from "./CardV2.types.js";

  let {
    href = undefined,
    base64Href = undefined,
    asButton = false,
    ocAriaLabel = undefined,
  }: Props = $props();

  const Host = $host<HTMLElement>();

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );
</script>

<InteractiveElement
  {asButton}
  bind:href
  {base64Href}
  class={`card`}
  interactiveClass="card--interactive"
  aria-label={ocAriaLabel}
>
  <slot />
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .card {
    all: unset;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: tokens.$oc-component-card-default-background-color;
    border-radius: tokens.$oc-component-card-100-border-radius;
    overflow: hidden;

    & {
      @include mixins.focus-styles(tokens.$oc-component-card-100-border-radius);
    }

    &--interactive {
      cursor: pointer;

      @media (hover: hover) {
        &:hover::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: inherit;
          pointer-events: none;
          background-color: tokens.$oc-component-card-overlay-hover;
        }
      }

      &:active::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        pointer-events: none;
        background-color: tokens.$oc-component-card-overlay-active;
      }
    }
  }
</style>
