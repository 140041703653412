<script lang="ts">
  type FormIconType = "clear" | "success-hint" | "warning-hint";
  type FormLabelIconType = "error" | "success" | "warning";

  interface Props {
    variant?: FormLabelIconType | string | undefined;
  }

  let { variant = undefined }: Props = $props();

  const types = {
    error: "clear",
    success: "success-hint",
    warning: "warning-hint",
  } as Record<string, FormIconType>;

  let type = $derived(variant ? types[variant] : undefined);
</script>

{#if type}
  <oc-icon-v1 {type} size={"50"} color="currentColor"></oc-icon-v1>
{/if}

<style lang="scss" global>
  oc-icon-v1 {
    flex: 0 0 auto;
  }
</style>
