/*                            */
/*                                                        */
import {
  ElementEventCallback,
  ElementsClickObserver,
  ElementsScrollObserver,
  ElementsVisibleObserver,
} from '../../adapters'

import { AppsBannerEvents } from './apps-banner-events.enum'
import { AppsPort, CmpImpl, EventBusPort } from '../../../shared/ports'
import { TrackingService } from '../tracking-service/tracking-service'
import { PerformanceTrackingService } from '../performance-tracking-service/performance-tracking-service'

interface ButtonCallbackConfig {
  callback: ElementEventCallback<'click'>
  selector: string
}

export class OnetrustCookieBanner {
  static readonly VERSION = 'onetrustCB'
  static readonly BODY_CLASS_BANNER_VISIBLE = 'onetrust-cookie-banner-visible'
  static readonly FIRST_LAYER_SCROLL_CONTAINER_ID = '#onetrust-policy'
  static readonly BANNER_ID = '#onetrust-banner-sdk'

  constructor(
    private readonly _document: Document,
    private readonly cmp: CmpImpl,
    private readonly appsPort: AppsPort,
    private readonly eventBus: EventBusPort,
    private readonly elementClickObserver: ElementsClickObserver,
    private readonly elementsScrollObserver: ElementsScrollObserver,
    private readonly elementsVisibleObserver: ElementsVisibleObserver,
    private readonly trackingService: TrackingService,
    private readonly performanceTrackingService: PerformanceTrackingService
  ) {}

  registerLinkTrackingListener(): void {
    const callback: ElementEventCallback<'click'> = (
      _element,
      event: Event
    ) => {
      const href = this.getHrefFromEvent(event)
      if (href) {
        event.preventDefault()
        this.trackAndOpenLink(href)
      }
    }
    this.elementClickObserver.observe('#onetrust-banner-sdk a', callback)
  }

  registerEventListener(): void {
    const buttonConfigs: Array<ButtonCallbackConfig> = [
      {
        selector: '#onetrust-accept-btn-handler',
        callback: () => {
          this.trackingService.trackOTFirstLayerAgree()
        },
      },
      {
        selector: '#onetrust-reject-all-handler',
        callback: () => {
          this.trackingService.trackOTFirstLayerDisagree()
        },
      },
      {
        selector: '#onetrust-pc-btn-handler',
        callback: () => {
          this.trackingService.trackOTFirstLayerSettings()
          this.trackingService.trackOTPreferenceCenterView()
        },
      },
      {
        selector: '#accept-recommended-btn-handler',
        callback: () => {
          this.trackingService.trackOTPreferenceCenterAgreeAll()
        },
      },
      {
        selector: '.ot-pc-refuse-all-handler',
        callback: () => {
          this.trackingService.trackOTPreferenceCenterDisagreeAll()
        },
      },
      {
        selector: '.save-preference-btn-handler',
        callback: () => {
          this.trackingService.trackOTPreferenceCenterAgreeMySelection()
        },
      },
    ]

    this.registerButtonAndLinkEventListeners(buttonConfigs)
    this.registerScrollListener()
    this.registerElementVisibleEventListener()
  }

  /*                                                                              */
  async resurfaceBanner(): Promise<void> {
    if (
      this.appsPort.isApp() &&
      !(this.appsPort.apps()?.shouldShowWebCookieBanner() ?? true)
    ) {
      this.eventBus.emit(AppsBannerEvents.ResurfaceBanner)
      return
    }

    await this.cmp.togglePreferenceCenter()
    this.trackingService.trackOTPreferenceCenterView()
  }

  private trackAndOpenLink(href: string) {
    const openLink = () => (this._document.location.href = href)
    /*                                                         */
    setTimeout(openLink, 100)
    this.trackingService.trackOTFirstLayerTextLink(
      this.extractPathForTracking(href)
    )
  }

  private getHrefFromEvent(event: Event) {
    if (event.currentTarget instanceof HTMLAnchorElement) {
      event.preventDefault()
      const element = event.currentTarget
      return element.href
    }
    return null
  }

  private extractPathForTracking(href: string): string {
    const url = new URL(href)
    return url.pathname.replace(/^\/|\/$/g, '')
  }

  private registerButtonAndLinkEventListeners(
    buttonConfigs: Array<ButtonCallbackConfig>
  ): void {
    buttonConfigs.forEach((config) => {
      this.elementClickObserver.observe(config.selector, config.callback)
    })
  }

  private registerScrollListener(): void {
    this.elementsScrollObserver.observeOnce(
      OnetrustCookieBanner.FIRST_LAYER_SCROLL_CONTAINER_ID,
      () => this.trackingService.trackOTFirstLayerScroll()
    )
  }

  private registerElementVisibleEventListener(): void {
    this.elementsVisibleObserver.observeOnce(
      OnetrustCookieBanner.BANNER_ID,
      () => {
        this.trackingService.trackOTFirstLayerView()
        this.performanceTrackingService.trackOTBannerLoadTime()
      }
    )
    this.elementsVisibleObserver.observe(
      OnetrustCookieBanner.BANNER_ID,
      (isVisible: boolean) => {
        if (isVisible) {
          this._document.body.classList.add(
            OnetrustCookieBanner.BODY_CLASS_BANNER_VISIBLE
          )
        } else {
          this._document.body.classList.remove(
            OnetrustCookieBanner.BODY_CLASS_BANNER_VISIBLE
          )
        }
      }
    )
  }

  /*                                 */
  /**
 *
 */
  async accept(): Promise<void> {
    await this.cmp.allowAll()
    await this.awaitLegacyConsentAcceptedEvent()
    return undefined
  }

  /**
 *
 */
  async reject(): Promise<void> {
    await this.cmp.rejectAll()
    await this.awaitLegacyConsentAcceptedEvent()
    return undefined
  }

  private awaitLegacyConsentAcceptedEvent(): Promise<Array<unknown>> {
    return this.eventBus.once('ft4.cookie-banner.consentAccepted')
  }
}
