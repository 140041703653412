import { GlobalOnexConfig } from './libs/onex/src'
import { config as liveConfig } from './onex-config'

export const config: GlobalOnexConfig = {
  ...liveConfig,
  active: false,
  localE2eTestsIncludeOnex: false,
  activeOnexConfig: {
    ...liveConfig.activeOnexConfig,
  },
}
