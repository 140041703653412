import { AS } from "@gr-common/body/namespaces";

/*                                                   */
otto.debug.assignNamespace(
  {
    /*                                                            */
    sendLog: (namespace, data) => {
      /*                                                */
      /*                                          */
      AS.RUM.sendCustomRequestOld(namespace, data);
      return otto.apm.sendCustomError(namespace, data);
    },
  },
  { lock: import.meta.env.VITE_OTTO_IS_TESTING_BUNDLE !== "true" },
);
