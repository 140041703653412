/*                                     */
/**
 *
 *
 *
 *
 */

import { o_global } from "@gr-common/head/namespaces";
import { otto, OttoNexus } from "./nexus.js";

export type * from "../lib/debug/index.js";

type DebugAsync = OttoNexus["debug"];

/*                                             */
export const debug: DebugAsync = otto.debug ?? {};

/*                                                */
export const sendLog: DebugAsync["sendLog"] = debug.sendLog;

/*                                             */
export const debugSync = o_global.debug ?? {};
export const logger = debugSync.logger;
export const scope = debugSync.scope;
export const Level = debugSync.Level;
export const status = debugSync.status;
