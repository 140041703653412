/*                                     */
/*                                     */
import type { Class } from "type-fest";
import { onLoad } from "@otto-ec/global-resources/dom";
import { addLabels } from "@otto-ec/global-resources/apm";
import { stateSyntax } from "@otto-ec/otto-components-utils/utils";

declare global {
  interface Window {
    /**
 *
 *
 */
    __components: {
      /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
      extend: typeof extendCustomElement;
    };
  }
}

/**
 *
 *
 */
export type CustomElement = Class<
  HTMLElement & {
    /**
 *
 */
    connectedCallback(): void;
    /**
 *
 */
    disconnectedCallback(): void;
    /**
 *
 */
    adoptedCallback(): void;
    /**
 *
 *
 *
 *
 */
    attributeChangedCallback(name: string, oldValue: string, newValue: string): void;
  }
> & {
  /**
 *
 */
  readonly observedAttributes: string[];
};

/*                            */
const instanceMap = new Map<string, null[]>();
/*                                                        */
function addInstance(tag: string): void {
  if (!instanceMap.has(tag)) {
    instanceMap.set(tag, []);
  }
  const list = instanceMap.get(tag)!;
  list.push(null);
}

/*                                                                    */
if (import.meta.env.PROD && !import.meta.env.STORYBOOK) {
  /*                    */
  onLoad.sub(() =>
    /*                                    */
    addLabels(
      /*                                          */
      Object.fromEntries(
        [...instanceMap.entries()].map(([tag, list]) => [
          `components-count_${tag.toLowerCase()}`,
          list.length,
        ]),
      ),
    ),
  );
}

/**
 *
 *
 *
 *
 */
function extendCustomElement({
  delegateFocus,
  formAssociated,
  inputBehavior,
}: {
  /**
 *
 *
 */
  formAssociated?: true;
  /**
 *
 */
  delegateFocus?: true;
  /**
 *
 *
 */
  inputBehavior?: true;
} = {}) {
  return (customElement: Class<HTMLElement>): CustomElement => {
    let result = class extends (customElement as CustomElement) {
      connectedCallback(): void {
        /*                               */
        addInstance(this.tagName);
        /*                                                                         */
        return super.connectedCallback();
      }
    };

    if (delegateFocus) {
      result = class extends result {
        constructor() {
          super();
          this.attachShadow({ mode: "open", delegatesFocus: true });
        }
      };
    }

    if (formAssociated) {
      result = class extends result {
        static formAssociated = true;

        internals: ElementInternals;

        constructor() {
          super();
          this.internals = this.attachInternals() as unknown as ElementInternals;
        }

        get form(): HTMLFormElement | null {
          return this.internals.form;
        }

        set form(value: unknown) {
          if (typeof value === "string") {
            this.setAttribute("form", value);
          }
        }

        formResetCallback(): void {
          /*                                                         */
          /*         */
          this.resetForm?.();
        }
      };
    }

    if (inputBehavior) {
      result = class extends result {
        get defaultValue(): string {
          return this.getAttribute("value") ?? "";
        }

        set defaultValue(value: string) {
          this.setAttribute("value", value);
        }

        get defaultChecked(): boolean {
          return this.hasAttribute("checked");
        }

        set defaultChecked(value: boolean) {
          if (value) {
            this.setAttribute("checked", "");
          } else {
            this.removeAttribute("checked");
          }
        }
      };
    }

    return result;
  };
}

window.__components ??= {} as never;
window.__components.extend = extendCustomElement;
