<svelte:options
  customElement={{
    tag: "oc-selection-tile-v1",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true, inputBehavior: true }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      color: { type: "String" },
      fillParent: { type: "Boolean", attribute: "fill-parent" },
      singleSelection: { type: "Boolean", attribute: "single-selection" },
      imgSrc: { type: "String", attribute: "img-src" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import {
    implicitSubmit,
    refireNonComposableNativeEvent,
    singleSelection as useSingleSelection,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import type { Props } from "./SelectionTileV1.types.js";

  let {
    variant = "text",
    disabled = false,
    checked = false,
    value = "on",
    name = undefined,
    fillParent = false,
    color = undefined,
    imgSrc = undefined,
    singleSelection = false,
    ocAriaLabel = undefined,
    internals,
  }: Props & {
    internals: ElementInternals;
  } = $props();

  const Host = $host();

  export function resetForm() {
    /*                            */
    checked = Host.hasAttribute("checked");
  }

  $effect(() => {
    internals.setFormValue(checked ? value : null);
  });

  const onChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!event.defaultPrevented) checked = input.checked;
  };
</script>

<label
  class="selection-tile {`selection-tile--variant-${variant}`} "
  class:selection-tile--fill-parent={fillParent}
  class:selection-tile--checked={checked}
  class:selection-tile--disabled={disabled}
  class:selection-tile--variant-container--fill-parent={variant === "container" && fillParent}
  style:--color={color}
  style:--imgSrc={imgSrc ? `url(${imgSrc})` : undefined}
  aria-label={ocAriaLabel}
  use:stopLabelClickPropagation
>
  {#if singleSelection}
    <input
      type="radio"
      class="selection-tile__input"
      {checked}
      {name}
      {disabled}
      use:useSingleSelection={{ Host, name, checked }}
      use:refireNonComposableNativeEvent={Host}
      use:implicitSubmit={internals}
      onchange={onChange}
      aria-label={ocAriaLabel}
    />
  {:else}
    <input
      type="checkbox"
      class="selection-tile__input"
      bind:checked
      {name}
      {disabled}
      use:refireNonComposableNativeEvent={Host}
      use:implicitSubmit={internals}
      aria-label={ocAriaLabel}
    />
  {/if}
  {#if variant === "text" || variant === "container"}
    <slot />
  {/if}
</label>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    /*        */
    --color: unset;
    --imgSrc: unset;

    @include mixins.no-tap-highlight();
    display: block;
  }

  .selection-tile {
    position: relative;
  }

  .selection-tile__input {
    all: unset;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;

    & {
      @include mixins.focus-styles(inherit);
    }
  }

  @mixin selection-tile-rounded {
    padding: 0;
    width: tokens.$oc-component-selection-tile-round-size;
    height: tokens.$oc-component-selection-tile-round-size;
    border-radius: 50%;
  }

  @mixin pseudo-element() {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  .selection-tile {
    /*     */
    position: relative;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    box-sizing: border-box;
    cursor: pointer;

    /*    */
    border-radius: tokens.$oc-component-selection-tile-roundrect-border-radius;
    background: tokens.$oc-component-selection-tile-background-color;
    outline: tokens.$oc-component-selection-tile-border-color solid
      tokens.$oc-component-selection-tile-border-width;
    color: tokens.$oc-component-selection-tile-text-color;

    &--variant-text {
      font: tokens.$oc-component-selection-tile-text-font;
    }

    &--variant-color {
      @include selection-tile-rounded();
      background-color: var(--color);

      height: tokens.$oc-component-selection-tile-round-size;
      width: tokens.$oc-component-selection-tile-round-size;
      padding: unset;
    }

    &--variant-image {
      @include selection-tile-rounded();
      background-image: var(--imgSrc);
      background-position: center;
      background-size: 100%;

      height: tokens.$oc-component-selection-tile-round-size;
      width: tokens.$oc-component-selection-tile-round-size;
      padding: unset;
    }

    &--variant-container {
      padding: 0;
    }

    &--fill-parent {
      width: 100%;
    }

    @media (hover: hover) {
      &:hover:not(.selection-tile--disabled):not(.selection-tile--checked) {
        outline: tokens.$oc-component-selection-tile-border-color-hover solid
          tokens.$oc-component-selection-tile-border-width-hover;
      }
    }

    @media (hover: hover) {
      &:hover:not(.selection-tile--disabled):not(.selection-tile--checked)::after {
        @include pseudo-element();

        outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
          tokens.$oc-component-selection-tile-inner-border-width-hover;
        outline-offset: -1px;
      }
    }

    &:active:not(.selection-tile--checked):not(.selection-tile--disabled) {
      outline: tokens.$oc-component-selection-tile-border-color-active solid
        tokens.$oc-component-selection-tile-border-width-active;
    }

    &:active:not(.selection-tile--checked):not(.selection-tile--disabled)::after {
      @include pseudo-element();

      outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
        tokens.$oc-component-selection-tile-inner-border-width-active;
      outline-offset: -1px;
    }

    &--checked {
      outline: tokens.$oc-component-selection-tile-border-color-selected solid
        tokens.$oc-component-selection-tile-border-width-selected;

      @media (hover: hover) {
        &:hover:not(.selection-tile--disabled) {
          outline: tokens.$oc-component-selection-tile-border-color-selected-hover solid
            tokens.$oc-component-selection-tile-border-width-selected-hover;
        }
      }

      &:active:not(.selection-tile--disabled) {
        outline: tokens.$oc-component-selection-tile-border-color-selected-active solid
          tokens.$oc-component-selection-tile-border-width-selected-active;
      }
    }

    &--checked::after {
      @include pseudo-element();

      outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
        tokens.$oc-component-selection-tile-inner-border-width-selected;
      outline-offset: -2px;

      @media (hover: hover) {
        &:hover::after {
          outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
            tokens.$oc-component-selection-tile-inner-border-width-selected;
          outline-offset: -1px;
        }
      }

      &:active::after {
        outline: tokens.$oc-component-selection-tile-border-color-selected-active solid
          tokens.$oc-component-selection-tile-inner-border-width-selected;
        outline-offset: -1px;
      }
    }

    &--disabled {
      outline: none;
    }

    /*                                           */
    &--disabled::after {
      @include pseudo-element();
      cursor: default;

      background-color: tokens.$oc-component-selection-tile-disabled-overlay;
      outline: tokens.$oc-component-selection-tile-disabled-border-color solid
        tokens.$oc-component-selection-tile-disabled-border-width;
      outline-offset: 0;
    }
  }
</style>
