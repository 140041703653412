<svelte:options
  customElement={{
    tag: "oc-auth-field-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      variant: { type: "String" },
      passwordVisible: { type: "Boolean", attribute: "password-visible" },
      showCounter: { type: "Boolean", attribute: "show-counter" },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";
  import CounterV1 from "../../../common/components/CounterV1.svelte";
  import type { Props } from "./AuthFieldV1.types";
  import { FormLabelIconV1 } from "../../../common/components/FormLabelIcon";
  import { usePropertyInterceptor } from "../../../common/utils/usePropertyInterceptor";
  import { waitForNextAnimationFrame } from "../../../common/utils/waitForNextAnimationFrame";

  let { variant = "default", passwordVisible = false, showCounter = false }: Props = $props();

  const Host = $host();

  let inputElement: HTMLInputElement | null = $state(Host.querySelector("input"));
  let labelElement: HTMLLabelElement | null = $state(Host.querySelector("label"));

  let labelShouldAnimate = $state(true);

  let value = $state("");
  let type = $state("");
  let maxlength: number | undefined = $state();
  let minlength: number | undefined = $state();
  let isPasswordField = $state(false);

  const syncValue = () => {
    value = inputElement?.value ?? "";
  };

  const syncType = () => {
    const currentType = inputElement?.type ?? "";
    if (type !== currentType) {
      /*                                                                                      */
      isPasswordField = inputElement?.type === "password";
    }
    type = currentType;
  };

  const syncMinLength = () => {
    minlength =
      inputElement?.minLength && inputElement.minLength >= 0 ? inputElement.minLength : undefined;
  };

  const syncMaxLength = () => {
    maxlength =
      inputElement?.maxLength && inputElement.maxLength >= 0 ? inputElement.maxLength : undefined;
  };

  $effect(() => {
    syncValue();
    syncType();
    syncMinLength();
    syncMaxLength();

    /*                                              */
    /*                                               */
    return usePropertyInterceptor(inputElement, "value", {
      /*                           */
      set: async () => {
        labelShouldAnimate = false;
        await waitForNextAnimationFrame();
        syncValue();
        await waitForNextAnimationFrame();
        labelShouldAnimate = true;
      },
    });
  });

  $effect(() => {
    if (labelElement) labelElement.slot = "label";
  });

  onMount(() => {
    const observer = new MutationObserver((changes) => {
      if (inputElement !== Host.querySelector("input")) {
        inputElement = Host.querySelector("input");
      }

      if (labelElement !== Host.querySelector("label")) {
        labelElement = Host.querySelector("label");
      }

      changes
        .filter(
          (change) => change.type === "attributes" && change.target === (inputElement as Node),
        )
        .forEach((change) => {
          if (change.attributeName === "type") {
            syncType();
          } else if (change.attributeName === "minlength") {
            syncMinLength();
          } else if (change.attributeName === "maxlength") {
            syncMaxLength();
          }
        });
    });

    observer.observe(Host, {
      childList: true,
      attributes: true,
      subtree: true,
      attributeFilter: ["type", "minlength", "maxlength"],
    });

    return () => observer.disconnect();
  });

  $effect(() => {
    if (inputElement && isPasswordField) {
      type = passwordVisible ? "text" : "password";
      inputElement.type = type;
    }
  });

  const togglePasswordVisibility = (ev: Event) => {
    passwordVisible = !passwordVisible;
    ev.stopPropagation();
    ev.preventDefault();
  };

  let isFocused = $state(false);
  const onFocus = (focus: boolean) => () => {
    isFocused = focus;
  };

  let moveLabelToTop = $derived(value.length > 0 || isFocused);
</script>

<div
  class={`auth-field auth-field--${variant}`}
  class:auth-field--with-password={isPasswordField}
  oninput={syncValue}
  onfocusin={onFocus(true)}
  onfocusout={onFocus(false)}
>
  <slot></slot>
  <div
    class="label"
    class:label--label-top={moveLabelToTop}
    class:label--no-animation={!labelShouldAnimate}
  >
    <slot name="label"></slot>
    <FormLabelIconV1 {variant} />
  </div>
  {#if isPasswordField}
    <!-- svelte-ignore a11y_no_static_element_interactions -->
    <!-- svelte-ignore a11y_click_events_have_key_events -->
    <oc-link-v2
      class="auth-field__password-toggle"
      variant="secondary"
      size={"75"}
      asButton={true}
      onclick={togglePasswordVisibility}
      onfocusin={(ev) => {
        ev.stopPropagation();
      }}
      onfocusout={(ev) => {
        ev.stopPropagation();
      }}
      ocAriaLabel={passwordVisible ? "Passwort ausblenden" : "Passwort anzeigen"}
      >{passwordVisible ? "Ausblenden" : "Anzeigen"}</oc-link-v2
    >
  {/if}
</div>

<div class="two-columns">
  <div>
    <slot name="error"></slot>
    <slot name="hint"></slot>
  </div>
  {#if showCounter}
    <CounterV1 counterValue={value} maxCounter={maxlength} minCounter={minlength} />
  {/if}
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  $autofill-background-color: #f0f4fc;
  $transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  $gap: 8px;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .auth-field {
    box-sizing: border-box;
    position: relative;

    &:hover ::slotted(input) {
      outline-width: 2px;
    }

    &:focus-within {
      ::slotted(input) {
        outline-width: 2px;
      }

      &:not(.auth-field--error) ::slotted(input) {
        outline-color: tokens.$oc-component-form-default-border-color-focus;
      }
    }

    ::slotted(input) {
      all: unset;
      box-sizing: border-box;

      background: tokens.$oc-component-form-background-color;
      outline: 1px solid tokens.$oc-component-form-default-border-color;

      padding: tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;
      width: 100%;

      color: tokens.$oc-component-form-field-input-color;

      &::placeholder {
        font: tokens.$oc-component-form-field-placeholder-font;
        color: tokens.$oc-component-form-field-placeholder-color;
        opacity: 0;
        transition: $transition;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        box-sizing: border-box;
      }
    }

    ::slotted(label) {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      cursor: text;
    }

    .label {
      position: absolute;
      top: tokens.$oc-component-form-field-spacing-y;
      left: tokens.$oc-semantic-spacing-50;
      padding: 0 tokens.$oc-semantic-spacing-25;
      max-width: calc(100% - 2 * tokens.$oc-semantic-spacing-50);
      box-sizing: border-box;

      transition: $transition;

      font: tokens.$oc-component-form-label-font;
      background-color: tokens.$oc-component-form-background-color;
      border-radius: tokens.$oc-component-form-field-label-border-radius;
      color: tokens.$oc-component-form-default-label-color;

      /*                            */
      display: flex;
      align-items: center;
      gap: tokens.$oc-component-form-state-icon-gap-x;

      &--label-top {
        top: -0.5rem;
        left: tokens.$oc-component-form-field-floating-label-spacing-left;
        padding: 0 tokens.$oc-component-form-field-floating-label-inner-spacing-x;
        font: tokens.$oc-component-form-field-floating-label-font;
      }

      &--no-animation {
        transition: none;
      }
    }

    /*                                       */
    &--with-password {
      $passwordToggleWidth: calc(tokens.$oc-component-form-field-spacing-x + 75px + $gap);

      ::slotted(input) {
        padding-right: $passwordToggleWidth;
      }

      .label:not(.label--label-top) {
        max-width: calc(100% - $passwordToggleWidth);
      }
    }

    &__password-toggle {
      position: absolute;
      display: flex;
      justify-content: right;
      box-sizing: border-box;

      top: 0;
      bottom: 0;
      right: 0;
      min-width: 90px;

      cursor: pointer;

      padding: tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;
    }

    &--error {
      ::slotted(input) {
        outline-color: tokens.$oc-component-form-error-border-color;
      }

      .label {
        color: tokens.$oc-component-form-error-label-color;
      }
    }
  }

  ::slotted([slot="error"]) {
    font: tokens.$oc-component-form-validation-message-font;
    color: tokens.$oc-component-form-error-validation-message-color;
    word-break: break-word;
  }

  ::slotted([slot="hint"]) {
    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-default-hint-color;
    word-break: break-word;
  }

  .two-columns {
    display: grid;
    grid-column-gap: 16px;
    width: 100%;

    > .counter {
      margin-top: tokens.$oc-component-form-hint-gap-y;
      justify-self: end;
      grid-column-start: 2;
    }
  }
</style>
