import { cookie } from "@otto-ec/global-resources/cookie";
import type { FunctionImplementation } from "@otto-ec/global-resources/nexus";

const cookeName = "oc_color_scheme_v1";
const attrName = "data-color-scheme";
const colorSchemes = ["dark", "auto"];

export const setColorScheme = function setColorScheme(colorScheme, cookieOptions) {
  const { documentElement: html } = document;

  /*                                                            */
  if (!colorScheme || !colorSchemes.includes(colorScheme)) {
    html.removeAttribute(attrName);
    cookie.remove(cookeName);
    return;
  }

  /*                                                          */
  html.setAttribute(attrName, colorScheme);

  /*                                                          */
  if (cookieOptions !== null) {
    cookie.set(cookeName, colorScheme, cookieOptions);
  }
} satisfies FunctionImplementation<OttoComponentApi["setColorScheme"]>;

/*                                                                       */
setColorScheme((cookie.get(cookeName) as never) ?? null, null);
